import io from 'socket.io-client'
import store from '../../store'
var socket
var connectedToWO = false
var connectedToBOM = false
var connectedToInvoice = false

const connect = data => {
    try {
        if (!data) return null
        var { companyId } = data
        socket = undefined
        connectedToWO = false
        connectedToBOM = false
        connectedToInvoice = false
        socket = io(process.env.VUE_APP_WEBSOCKET_SERVER, {
            secure: true,
            transports: ['websocket'],
            upgrade: false,
            auth: {
                token: `${process.env.VUE_APP_EXTERNAL_AUTHORIZATION_TOKEN}`,
            },
        })

        socket.on('connect', () => {
            console.log('Socket connection successfully established.')
            socket.on(`${companyId}-BOM`, data => {
                console.log(data)
                store.dispatch('setBomUpdates', data)
            })
            socket.on(`${companyId}-WO`, data => {
                console.log(data)
            })
            socket.on(`${companyId}-INVOICE`, data => {
                store.dispatch('setInvoiceUpdates', data)
            })
        })

        socket.on('disconnect', reason => {
            console.log(`Connect socket.io closed. Reason: ${reason}`)
        })

        socket.on('reconnect', attemptNumber => {
            console.log(
                `Successful reconnection after ${attemptNumber} attempts.`
            )
        })
    } catch (error) {
        console.log(error.message)
    }
}

const joinRoom = room => {
    try {
        if (socket) {
            if (room.includes('WO') && !connectedToWO) {
                connectedToWO = true
            }
            if (room.includes('BOM') && !connectedToBOM) {
                connectedToBOM = true
            }
            if (room.includes('INVOICE') && !connectedToInvoice) {
                connectedToInvoice = true
            }
            socket.emit(
                'join',
                {
                    room,
                },
                error => {
                    if (error) {
                        console.log(error)
                    }
                }
            )
        }
    } catch (error) {
        console.log(error.message)
    }
}

const leaveRoom = room => {
    try {
        if (socket) {
            if (room.includes('WO') && connectedToWO) {
                connectedToWO = false
            }
            if (room.includes('BOM') && connectedToBOM) {
                connectedToBOM = false
            }
            if (room.includes('INVOICE') && connectedToInvoice) {
                connectedToInvoice = false
            }
            socket.emit('leaveRoom', room)
        }
    } catch (error) {
        console.log(error.message)
    }
}

const disconnect = () => {
    if (socket) {
        socket.disconnect()
        socket = undefined
    }
}

export default {
    connect,
    disconnect,
    socket,
    joinRoom,
    leaveRoom,
}
